<template >
  <el-form
    status-icon
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    label-width="110px"
   
  >
    <el-form-item label="手机号" prop="phone">
      <el-input
        placeholder="请输入您的手机号码"
        v-model.number="ruleForm.phone"
        autofocus
      ></el-input>
    </el-form-item>
    <el-form-item label="短信验证码" prop="vscode">
      <el-input
        ref="vscodeInp"
        class="vscodeInp"
        v-model="ruleForm.vscode"
        placeholder="请输入短信验证码"
        maxlength="6" onkeyup="value=value.replace(/[^\d\.]/g,'')" 
      >
      </el-input>
      <el-button
        ref="child"
        class="vscodeBtn"
        :class="{ disabled: !this.canClick }"
        @click="countDown"
        >{{ content }}</el-button
      >
    </el-form-item>
    <el-form-item label="密码" prop="pass">
      <el-input
        placeholder="请输入登录密码"
        type="password"
        v-model="ruleForm.pass"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <el-form-item label="设置密码" prop="checkPass">
      <el-input
        placeholder="请再次输入登录密码"
        type="password"
        v-model="ruleForm.checkPass"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <el-form-item label="邀请码(选填)">
      <el-input v-model="ruleForm.code" placeholder="请输入邀请码"></el-input>
    </el-form-item>
    <el-form-item label="关联药店地址" prop="area">
      <div class="areaBox">
        <el-select
          v-model="ruleForm.province"
          placeholder="省"
          @change="areaFun($event,1)"
          visible-change="true"
        >
          <el-option
            v-for="item in addressProvince"
            :key="item.code"
            :label="item.name"
            :value="{ value: item.code, label: item.name }"
          >
          </el-option>
        </el-select>
        <el-select
          v-if="Cityflag"
          v-model="ruleForm.city"
          placeholder="市"
          @change="areaFun($event,2)"
        >
          <el-option
            v-for="item in addressCity"
            :key="item.code"
            :label="item.name"
            :value="{ value: item.code, label: item.name }"
          >
          </el-option>
        </el-select>
        <el-select
          v-if="Areaflag"
          v-model="ruleForm.area"
          placeholder="县/区"
          @change="areaFun($event,3)"
        >
          <el-option
            v-for="item in addressArea"
            :key="item.code"
            :label="item.name"
            :value="{ value: item.code, label: item.name }"
          >
          </el-option>
        </el-select>
      </div>
    </el-form-item>

    <el-form-item label="药店名称" prop="name">
      <el-input v-model.number="ruleForm.name"   placeholder="药店名称"></el-input>
    </el-form-item>
    <!-- <el-button :disabled="true"  @click="submitForm('ruleForm')">注册</el-button> -->
    <el-button size="medium" class="registBtn" @click="submitForm('ruleForm')"
      >注册</el-button
    >
    <page-slide ref="child"  :timeMethod="timeMethod"></page-slide>
  </el-form>
</template>
<script>
import { Register, AreaPort ,registerCode} from "api/home.js";
const Slide = () => import("components/index/sliderVscode.vue");
import { mapMutations } from "vuex";
import { getToken} from "utils/session.js";
export default {
  name: "Register",
  components: {
    "page-slide": Slide,
  },
  data() {
    //表单验证
    let checkPhoto = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      }else{
          if (!Number.isInteger(value)) {
            callback(new Error("请输入数字值"));
          } else {
            if (!/^1[3456789]\d{9}$/.test(value)) {
              callback(new Error("请输入正确的手机号码!"));
            } else {
              callback();
            }
          }
      }
      
    };
    let checkVscode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("短信验证码不能为空"));
      }else{
         if (value.length<6) {
              callback(new Error("验证码为六位数字!"));
            } else {
              callback();
            }
      }
    };
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };


    return {
      // 滑块验证
      outerVisible: false,
      // 点击倒计时
      content: "发送验证码", // 按钮里显示的内容
      totalTime: 60, //记录具体倒计时时间
      canClick: true, //添加canClick
      ruleForm: {
        phone: '',
        vscode: "",
        pass: "",
        checkPass: "",
        code: "",
        province: "",
        city: "",
        area: "",
        provinceCode: "",
        cityCode: "",
        areaCode: "",
        name: "",
      },
      rules: {
        phone: [{required:true,message:"手机号必填", trigger: "blur"},{ validator: checkPhoto, trigger: "blur" }],
        vscode: [{required:true,message:"短信验证码必填", trigger: "blur"},{ validator: checkVscode, trigger: "blur" }],
        pass: [{required:true,message:"密码必填", trigger: "blur"},{ validator: validatePass, trigger: "blur" }],
        checkPass: [{required:true,message:"确认密码必填", trigger: "blur"},{ validator: validatePass2, trigger: "blur" }],
        area: [{required:true,message:"省市县必选", trigger: "change"}],
        name:[{required:true,message:"药店名称必填", trigger: "blur"}]
      },
      //市县显示开关
      Cityflag: false,
      Areaflag: false,
      addressProvince: [],
      addressCity: [],
      addressArea: [],
      // 药店名称 data
      name_options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
    };
  },
  watch: {
    // 滑块
    outerVisible() {
    },
  },

  mounted() {},

  //路由进入时  显示未登陆状态头部
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      
      vm.changeFlag(true);
    });
  },
  //路由离开时 隐藏未登陆状态头部
  beforeRouteLeave(to, from, next) {
    if(getToken()!=null){
      this.changeFlag(false);
    }
    next();
  },
  methods: {
    //省市县地区接口调取
    areaFun(params,i) {
      if(i===1){
        this.Cityflag = false;
        this.Areaflag = false;
      }else if(i===2){
          this.Areaflag = false;
      }
      const { value, label } = params;
      let code = value;
      AreaPort(code).then((data) => {
        if (data.data.data.length != 0) {
          if (data.data.data[0].level == 1) {
            this.ruleForm.province = label;

            this.ruleForm.provinceCode = code;
            this.addressProvince = data.data.data;
          } else if (data.data.data[0].level == 2) {
            this.ruleForm.city = label;
            this.ruleForm.cityCode = code;
            this.Cityflag = true;
            this.ruleForm.city = "";
            this.addressCity = [];
            this.addressCity = data.data.data;
          } else if (data.data.data[0].level == 3) {
            this.Areaflag = true;
            this.ruleForm.area = "";
            this.addressArea = [];
            this.addressArea = data.data.data;
          }
        } else {
          this.ruleForm.area = label;
          this.ruleForm.areaCode = code;
        }
      });
    },
    ...mapMutations({
      changeFlag: "changeFlag",
    }),
    // 倒计时
    timeMethod(){
        this.content = this.totalTime + "s后重新发送";
        let clock = window.setInterval(() => {
          this.totalTime--;
          this.content = this.totalTime + "s后重新发送";
          if (this.totalTime < 0) {
            window.clearInterval(clock);
            this.content = "重新发送验证码";
            this.totalTime = 60;
            this.canClick = true; //这里重新开启
          }
        }, 1000);
        //0注册    1改密码  2改手机号    发送验证码
        registerCode(this.ruleForm.phone,0).then((data)=>{
                if(data.data.code==400){
                   this.$message({
                    message: data.data.msg,
                    type: 'warning'
                  });
               }else{
                  this.$message({
                    message: data.data.msg,
                    type: 'success'
                  });
               }
        })
    },
    countDown() {
      if(this.ruleForm.phone==''){
        return
      }else{
        if (!Number.isInteger(this.ruleForm.phone)) {
          return
        } else {
          if (!/^1[34578]\d{9}$/.test(this.ruleForm.phone)) {
            return
          }
        }
      }
      if (this.canClick) {
        this.canClick = false;
        //$refs获取在子组件的属性和方法，子组件需定义ref，child就是在子组件中定义的ref
        this.$refs.child.outerVisible = true;

       
      } else {
        this.$refs.child.outerVisible = false;
        return; //改动的是这两行代码
      }
    },
    //   点击提交 验证
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            // 注册
            //  phone: "",
            //   vscode: "",   //短信验证码
            //   pass: "",
            //   checkPass: "",
            //   code: "",      //邀请码
            //   region: "",    //关联药店所在地
            //   name: "",      //药店名称
            let registerdata = {
                  account: this.ruleForm.phone, //手机号
                  idType: 2,
                  inviteCode: this.ruleForm.code, //邀请码
                  password:this.$md5(this.ruleForm.pass) ,
                  smsCode: this.ruleForm.vscode, //短信验证码
                  tenantId: "104704",
                  memberStore: this.ruleForm.name,
                  address: "1",
                  addressArea: this.ruleForm.areaCode,
                  addressCity: this.ruleForm.cityCode,
                  addressProvince: this.ruleForm.provinceCode,
                };
            Register(registerdata).then((data) => {
               if(data.data.code==400){
                 
                  let msg=data.data.msg;
                  if(msg=='验证码错误，请重新输入'){
                      this.ruleForm.vscode=''
                      this.$refs.vscodeInp.focus();
                  }
                   this.$message({
                    message: data.data.msg,
                    type: 'warning'
                  });
               }else{
                  this.$message({
                    message: '恭喜您，您已注册成功',
                    type: 'success'
                  });
                  this.$router.push({ name: "Login" });
               }
             
            });
           
        } else {
          return false;
        }

        
      });
    },
  },
  created() {
    if(sessionStorage.getItem("vscodemobile")){
        this.ruleForm.phone=Number(sessionStorage.getItem("vscodemobile"))
    }
     if(sessionStorage.getItem("vscode")){
        this.ruleForm.vscode=sessionStorage.getItem("vscode")
    }
     AreaPort('00').then((data) => {
        this.addressProvince = data.data.data;
        this.addressProvince =this.addressProvince.filter((item) => item.name!="广东省"&&item.name!="海南省");
        console.log('this.addressProvince',this.addressProvince)
     })
  },
};
</script>

<style lang="less" scoped>
.el-form {
  background: #fff;
  padding: 50px 0px 50px 20%;
  width: 1200px;
  margin: 0 auto;
  // el-button
  .registBtn {
    width: calc(60% - 60px);
    background: #e73a37;
    color: #fff;
    margin-left: 110px;
  }

  .el-select {
    width: 60%;
  }
  .areaBox {
    width: 60%;
    display: flex;
    flex-direction: row;
    .el-select:nth-child(1) {
      margin-right: 5px;
    }
    .el-select:nth-child(2) {
      margin-left: 5px;
      margin-right: 5px;
    }
    .el-select:nth-child(3) {
      margin-left: 5px;
    }
  }
  .el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 60%;
  }

  .vscodeInp {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 40%;
    /deep/.el-input__inner {
      border-right: none;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  .vscodeBtn {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 20%;
    background: #ffcccc;
    color: #ff3333;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    cursor: pointer;
  }
  .disabled {
    background-color: #ddd;
    border-color: #ddd;
    color: #57a3f3;
    cursor: not-allowed; // 鼠标变化
  }
}
/deep/.el-form-item__error {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 10px;
  left: calc(60% + 10px);
}
</style>
